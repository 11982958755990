import { formatDate } from "../../framework/common";
import { PlainDatacomDeliveryData } from "../level-1/mapper";

export type DetailsListData = (
  | { type: "TEXT"; label: string; valueLines: string[] }
  | { type: "BADGE"; label: string; variant: string; text: string }
)[];

const promisedIsRelevant = (promised: Date | null, latestPromised: Date | null) => {
  // promised (aka originally promised) should only be shown if there is also a "latestPromised", with a different value.
  if (promised) {
    if (latestPromised) {
      // they should never be equal
      return promised.getTime() !== latestPromised.getTime();
    } else {
      // should never happen
      return false;
    }
  } else {
    return false;
  }
};

const buildLongOrderStatus = (
  statusCategory: "OPEN" | "CLOSED",
  orderStatus: string | undefined,
  t: Function
) => {
  const orderStatusCategoryTranslated =
    statusCategory === "OPEN"
      ? t("value.orderStatus.singleItemCategory.open")
      : t("value.orderStatus.singleItemCategory.closed");

  const orderStatusTranslated = t(`value.orderStatus.specific.${orderStatus}`);

  if (orderStatusCategoryTranslated === orderStatusTranslated) {
    return orderStatusCategoryTranslated;
  } else {
    return `${orderStatusCategoryTranslated} (${orderStatusTranslated})`;
  }
};

const getNormalizedSingleValueLine = (inputLine: string): string[] => {
  return getNormalizedMultipleValueLine([inputLine]);
};

const getNormalizedMultipleValueLine = (inputLines: string[]): string[] => {
  return inputLines.filter((inputLine) => inputLine && inputLine.length > 0);
};

const getNormalizedDateValueLine = (inputDate: Date | null): string[] => {
  return getNormalizedSingleValueLine(formatDate(inputDate as Date | null));
};

export const getDetails = (
  plainData: PlainDatacomDeliveryData,
  t: Function
): {
  deliveryId: string;
  generalDetails: DetailsListData;
  deliveryDetails: DetailsListData;
  subscriptionDetails: DetailsListData;
} => ({
  deliveryId: (plainData.deliveryId as string) ?? "",
  generalDetails: [
    {
      type: "TEXT",
      label: t("column.deliveryType.title"),
      valueLines: getNormalizedSingleValueLine(plainData.deliveryType),
    },
    {
      type: "BADGE",
      label: t("column.orderStatus.title"),
      variant: plainData.statusCategory === "OPEN" ? "information" : "success",
      text: buildLongOrderStatus(
        plainData.statusCategory as "OPEN" | "CLOSED",
        plainData.orderStatus as string,
        t
      ),
    },
    {
      // on old TSE page, this had the Voca icon "reservation"
      type: "TEXT",
      label: t("column.registeredDate.title"),
      valueLines: getNormalizedDateValueLine(plainData.registeredDate),
    },
    {
      // on old TSE page, this had the Voca icon "delivery"
      type: "TEXT",
      label: t("column.latestPromisedDate.title"),
      valueLines: getNormalizedDateValueLine(plainData.latestPromisedDate),
    },
    {
      // on old TSE page, this had the Voca icon "checkmark"
      type: "TEXT",
      label: t("column.readyDate.title"),
      valueLines: getNormalizedDateValueLine(plainData.readyDate),
    },
  ],
  deliveryDetails: [
    {
      type: "TEXT",
      label: t("details.deliveryDetailLabel.installationAddress"),
      valueLines: getNormalizedMultipleValueLine([
        plainData.installationName,
        plainData.installationAddress,
        `${(plainData.installationZipCode as string) ?? ""} ${
          (plainData.installationCity as string) ?? ""
        }`,
        plainData.installationCountry,
      ]),
    },
    {
      type: "TEXT",
      label: t("details.deliveryDetailLabel.organization"),
      valueLines: getNormalizedSingleValueLine(plainData.organizationName),
    },
    {
      type: "TEXT",
      label: t("details.deliveryDetailLabel.contactPerson"),
      valueLines: getNormalizedMultipleValueLine([plainData.contactPerson, plainData.contactPhone]),
    },
    {
      type: "TEXT",
      label: t("details.deliveryDetailLabel.customerDesiredDate"),
      valueLines: getNormalizedDateValueLine(plainData.customerDesiredDate),
    },
    {
      type: "TEXT",
      label: t("details.deliveryDetailLabel.promisedDate"),
      valueLines: getNormalizedDateValueLine(
        promisedIsRelevant(
          plainData.promisedDate as Date | null,
          plainData.latestPromisedDate as Date | null
        )
          ? plainData.promisedDate
          : null
      ),
    },
  ],
  subscriptionDetails: [
    {
      type: "TEXT",
      label: t("details.subscriptionDetailLabel.subscriptionId"),
      valueLines: getNormalizedSingleValueLine(plainData.subscriptionId),
    },
    {
      type: "TEXT",
      label: t("details.subscriptionDetailLabel.serviceType"),
      valueLines: getNormalizedSingleValueLine(plainData.serviceType),
    },
    {
      type: "TEXT",
      label: t("column.vpnId.title"),
      valueLines: getNormalizedSingleValueLine(plainData.vpnId),
    },
    {
      type: "TEXT",
      label: t("column.vpnAlias.title"),
      valueLines: getNormalizedSingleValueLine(plainData.vpnAlias),
    },
  ],
});
