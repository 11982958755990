import { logError } from "@telia/b2x-logging";

import type { DatacomDeliveriesUI } from "@telia/b2b-rest-client/dist/corp-installedbase-datacom";
import type { GetOrganizationResponse } from "@telia/b2b-rest-client/dist/corp-scope-information";
import type { VpnUI } from "@telia/b2b-rest-client/dist/corp-installedbase-vpn";

const findVpnAlias = (vpnId: string | undefined, allVpns: VpnUI[]): string => {
  if (vpnId) {
    const vpn = allVpns.find((vpn) => vpn.vpnId === vpnId);
    if (vpn) {
      return vpn.vpnAlias ?? "";
    }
  }
  return "";
};

const getOrganizationName = (
  organizationNumber: string | undefined,
  allOrganizations: GetOrganizationResponse[]
): string => {
  const _organizationNumber = organizationNumber ?? "";
  let organization = allOrganizations.find(
    (organization) =>
      organization.organizationNumber === _organizationNumber ||
      organization.organizationNumber ===
        _organizationNumber.substring(_organizationNumber.length - 10)
  );
  if (organization && organization.name) {
    return organization.name ?? "";
  } else {
    return _organizationNumber
      .substring(_organizationNumber.length - 10)
      .replace(/^(\d{6})(\d{4})$/, "$1-$2");
  }
};

const getValidDateObjectOrNull = (isoDate: string | undefined): Date | null => {
  isoDate = isoDate ?? "";
  if (isoDate.length !== 19 && isoDate.length !== 0) {
    logError("b2b-datacom-deliveries", "Unexpected date/time format");
  }

  const date = new Date(isoDate ?? "");
  return !isNaN(date.valueOf()) ? date : null;
};

type OrderStatus =
  | "REGISTERED"
  | "ONGOING"
  | "AWAIT_REPLY"
  | "CUSTOMER_READY"
  | "CANCELED"
  | "SENT_BACK"
  | "COMPLETED"
  | "RETURNED"
  | "UNKNOWN";

const STATUS_CLASSIFICATION: { open: OrderStatus[]; closed: OrderStatus[] } = {
  open: ["REGISTERED", "ONGOING", "AWAIT_REPLY"],
  closed: ["CUSTOMER_READY", "CANCELED", "SENT_BACK", "COMPLETED", "RETURNED", "UNKNOWN"],
};

const statusIsOpen = (status: string | undefined): boolean =>
  STATUS_CLASSIFICATION.open.indexOf((status as OrderStatus) ?? "UNKNOWN") > -1;

const getValidOrderStatus = (orderStatus: string | undefined): OrderStatus => {
  if (
    STATUS_CLASSIFICATION.open.some((status) => status === orderStatus) ||
    STATUS_CLASSIFICATION.closed.some((status) => status === orderStatus)
  ) {
    return orderStatus as OrderStatus;
  }
  return "UNKNOWN";
};

const getValidDeliveryId = (deliveryId: string | undefined): string =>
  deliveryId && deliveryId.length > 0 ? deliveryId : "0";

// ideally, this is how the data should come from BE.
// sub type of PlainData[]
export type PlainDatacomDeliveryData = {
  deliveryId: string;
  detailsUrl: string;
  statusCategory: "OPEN" | "CLOSED";
  orderStatus: OrderStatus;
  subscriptionId: string;
  serviceType: string;
  deliveryType: string;
  vpnId: string;
  vpnAlias: string;
  organizationName: string;
  installationName: string;
  installationAddress: string;
  installationZipCode: string;
  installationCity: string;
  installationCountry: string;
  contactPerson: string;
  contactPhone: string;
  registeredDate: Date | null;
  customerDesiredDate: Date | null;
  promisedDate: Date | null;
  latestPromisedDate: Date | null;
  readyDate: Date | null;
};

export const mapDeliveries = (
  deliveries: DatacomDeliveriesUI,
  organizations: GetOrganizationResponse[],
  vpns: VpnUI[],
  scopeId: string
): PlainDatacomDeliveryData[] => {
  if (deliveries.results) {
    return deliveries.results.map((item) => ({
      deliveryId: item.deliveryId ?? "",
      detailsUrl: `/foretag/mybusiness/${scopeId}/hantera/produkter-tjanster/datanet-vpn/leveranser/${getValidDeliveryId(
        item.deliveryId
      )}`,
      statusCategory: statusIsOpen(item.orderStatus) ? "OPEN" : "CLOSED",
      orderStatus: getValidOrderStatus(item.orderStatus), // several statuses
      subscriptionId: item.subscriptionId ?? "",
      serviceType: item.serviceType ?? "",
      deliveryType: item.deliveryType ?? "",
      vpnId: item.vpnId ?? "",
      vpnAlias: findVpnAlias(item.vpnId, vpns),
      organizationName: getOrganizationName(item.customerIdentificationNumber, organizations),
      installationName: item.installationName ?? "",
      installationAddress: item.installationAddress ?? "",
      installationZipCode: item.installationZipCode ?? "",
      installationCity: item.installationCity ?? "",
      installationCountry: item.installationCountry ?? "",
      contactPerson: item.contactPerson ?? "",
      contactPhone: item.contactPhone ?? "",
      registeredDate: getValidDateObjectOrNull(item.registeredDate),
      customerDesiredDate: getValidDateObjectOrNull(item.customerDesiredDate),
      promisedDate: getValidDateObjectOrNull(item.promisedDate),
      latestPromisedDate: getValidDateObjectOrNull(item.latestPromisedDate),
      readyDate: getValidDateObjectOrNull(item.readyDate),
    }));
  } else {
    return [];
  }
};
