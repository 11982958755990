<template>
  <div class="detail-list">
    <div class="label-and-value" v-for="(item, index) in nonEmptyItems" :key="index">
      <telia-heading tag="h4" variant="subsection-100">{{ item.label }}</telia-heading>
      <telia-p v-if="item.type === 'TEXT'">
        <div v-for="(valueLine, lineIndex) in item.valueLines" :key="lineIndex">
          {{ valueLine }}
        </div>
      </telia-p>
      <telia-p v-else-if="item.type === 'BADGE'">
        <telia-badge :variant="item.variant">{{ item.text }}</telia-badge>
      </telia-p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ComputedRef, computed } from "vue";
import type { DetailsListData } from "../services/datacom-deliveries/level-3/details-page-service";

interface Props {
  items: DetailsListData;
}

const props = defineProps<Props>();

const nonEmptyItems: ComputedRef<DetailsListData> = computed(() => {
  return props.items.filter(
    item => item.type === 'BADGE' || (item.type === 'TEXT' && item.valueLines.length > 0)
  );
});
</script>

<style lang="scss" scoped>
// voca telia-grid breakpoints does not work well within a drawer, making our own grid

.detail-list {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: var(--purpur-spacing-200);

  .label-and-value {
    flex-grow: 1;

    // must be higher than 33% (+/- some spacing) to prevent 3 items from fitting on 1 row
    // must be lower than 50% (+/- some spacing) to allow 1 or 2 items on 1 row
    flex-basis: 40%;

    // wins over flex-basis for smaller screens, force 1 item to use entire row instead of squeezing 2 items on short row
    min-width: 200px;
    // warning: will overflow on extremely small screens (less wide than than this value + spacing)
  }
}
</style>
