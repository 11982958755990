import { TableHeaderSortableIconType } from "@telia/b2x-table/dist/types/components/table-header-row/table-header-sortable-icon-type";

import type { ColumnDefinition } from "../level-2/agnostic-table-service";
import { AgnosticTableData, AgnosticCellData } from "../level-2/agnostic-table-service";
import type {
  BadgeCellData,
  LinkCellData,
  TextCellData,
} from "@telia/b2x-table/dist/types/components/table-cell/cell-data";

import type { SortOrder } from "../../framework/level-2/transformation-service";

import type { TableManagerColumn } from "@telia/b2x-table/dist/types/components/table-column-manager/table-column-manager";
import type { TableEntry } from "@telia/b2x-table/dist/types/components/table/table";

const getSortIconForColumn = (
  columnIndex: number,
  sortColumn: number | null,
  sortOrder: SortOrder
): TableHeaderSortableIconType => {
  if (columnIndex === sortColumn) {
    // ASC = 1,2,3 = arrow-up
    // DESC = 3,2,1 = arrow-down
    // this matches UI patterns of both Windows and MacOS
    switch (sortOrder) {
      case "ASC":
        return "arrow-up";
      case "DESC":
      default:
        return "arrow-down";
    }
  } else {
    return "sorter";
  }
};

export const getB2xTableColumns = (
  columnDefinitions: ColumnDefinition[],
  sortColumn: number | null,
  sortOrder: SortOrder,
  t: Function
) =>
  columnDefinitions.map((columnDefinition, index) => ({
    title: t(`column.${columnDefinition.key}.title`),
    type: getB2xTableColumnType(columnDefinition.type),
    isChecked: columnDefinition.defaultVisibility,
    disabled: false,
    sortable: { sortIconName: getSortIconForColumn(index, sortColumn, sortOrder) },
    filterable: getB2xTableColumnFilterConfiguration(columnDefinition, t),
    columnGroup: t(`columnGroup.${columnDefinition.columnGroup}`),
  })) as TableManagerColumn[];

const getB2xTableColumnType = (
  agnosticColumnType: "link" | "status" | "text" | "date"
): "sspa-link" | "badge" | "text" => {
  switch (agnosticColumnType) {
    case "link":
      return "sspa-link";
    case "status":
      return "badge";
    case "date":
    case "text":
    default:
      return "text";
  }
};

const convertRecordToArray = (
  record: Record<string, { displayFilterTranslationKey: string; filterSortValue: string }>,
  t: Function
) => {
  let output = new Array();
  for (let value in record) {
    output.push({
      value: value,
      displayName: t(record[value].displayFilterTranslationKey),
    });
  }

  output.sort(
    (
      a: { value: string; displayName: string },
      b: { value: string; displayName: string }
    ): number => {
      return record[a.value].filterSortValue.localeCompare(record[b.value].filterSortValue, "en");
    }
  );

  return output;
};

const getB2xTableColumnFilterConfiguration = (columnDefinition: ColumnDefinition, t: Function) => {
  switch (columnDefinition.type) {
    case "status":
      return {
        type: "enum",
        label: "STATUS",
        noSelectionDisplayName: t(columnDefinition.notSelectedDisplayNameTranslationKey),
        values: convertRecordToArray(columnDefinition.statusValues, t),
      };
    default:
      return {
        type: "text",
        label: t(`column.${columnDefinition.key}.filter`),
      };
  }
};

const getB2xTableCellData = (
  agnosticCellData: AgnosticCellData
): BadgeCellData | LinkCellData | TextCellData => {
  switch (agnosticCellData.type) {
    case "status":
      return {
        content: agnosticCellData.displayData,
        variant: agnosticCellData.badgeType,
      } as BadgeCellData;
    case "link":
      return {
        content: agnosticCellData.displayData,
        href: agnosticCellData.linkUrl,
      } as LinkCellData;
    default:
      return agnosticCellData.displayData as TextCellData;
  }
};

export const getB2xTableData = (agnosticTable: AgnosticTableData): TableEntry[] =>
  agnosticTable.map((agnosticRow) =>
    agnosticRow.cells.map((agnosticCell) => getB2xTableCellData(agnosticCell))
  );
