import { formatDate } from "../common";
import type { PlainData } from "../level-1/plain-data-type";

type CommonColumDefinitionFields = {
  key: string;
  defaultVisibility: boolean;
  columnGroup: string;
};

type TextColumnDefinition = {
  type: "text";
} & CommonColumDefinitionFields;

type DateColumnDefinition = {
  type: "date";
} & CommonColumDefinitionFields;

type LinkColumnDefinition = {
  type: "link";
  urlKey: string;
} & CommonColumDefinitionFields;

type StatusColumnDefinition = {
  type: "status";
  statusValues: Record<
    string,
    {
      displayFilterTranslationKey: string;
      displayTranslationKey: string;
      sortValue: string;
      filterSortValue: string;
      badgeType: "success" | "information";
    }
  >;
  notSelectedDisplayNameTranslationKey?: string;
  fallbackValue: string;
} & CommonColumDefinitionFields;

export type ColumnDefinition =
  | TextColumnDefinition
  | DateColumnDefinition
  | LinkColumnDefinition
  | StatusColumnDefinition;

export type AgnosticTableData = AgnosticRowData[];

type AgnosticStatusCellData = {
  displayData: string;
  badgeType: "information" | "success";
  type: "status";
  filterData: string;
  sortData: string;
};
type AgnosticTextCellData = {
  displayData: string;
  type: "text";
  filterData: string;
  sortData: string;
};
type AgnosticLinkCellData = {
  displayData: string;
  linkUrl: string;
  type: "link";
  filterData: string;
  sortData: string;
};
type AgnosticDateCellData = {
  displayData: string;
  type: "date";
  filterData: string; // if we switch to proper date range filtering: Date | null;
  sortData: Date;
};

export type AgnosticCellData =
  | AgnosticStatusCellData
  | AgnosticTextCellData
  | AgnosticLinkCellData
  | AgnosticDateCellData;

export type AgnosticRowData = {
  cells: AgnosticCellData[];
  plainData: PlainData;
};

const mapStatus = (
  columnDefinition: StatusColumnDefinition,
  rawValue: string,
  t: Function
): AgnosticStatusCellData => {
  const valueKey = columnDefinition.statusValues[rawValue]
    ? rawValue
    : columnDefinition.fallbackValue;
  return {
    type: "status",
    displayData: t(columnDefinition.statusValues[valueKey].displayTranslationKey),
    filterData: valueKey,

    // when sorting ascending, sort open before closed.
    // reasoning: ongoing happens first, closed happens last. Within open status, just sort them in ANY order, just not mix
    sortData: columnDefinition.statusValues[valueKey].sortValue,
    badgeType: columnDefinition.statusValues[valueKey].badgeType,
  };
};

const mapText = (text: string): AgnosticTextCellData => ({
  type: "text",
  displayData: text,
  filterData: text,
  sortData: text,
});

const mapLink = (text: string, linkUrl: string): AgnosticLinkCellData => ({
  type: "link",
  displayData: text,
  filterData: text,
  sortData: text,
  linkUrl: linkUrl,
});

const mapDate = (dateOrNull: Date | null): AgnosticDateCellData => {
  return {
    type: "date",
    displayData: formatDate(dateOrNull),
    // filterData: validDateOrNull, // if we switch to proper date range filtering
    filterData: formatDate(dateOrNull),

    // treat missing date as oldest possible date when sorting
    sortData: dateOrNull ?? new Date(0),
  };
};

export const getAgnosticTableData = (
  columnDefinitions: ColumnDefinition[],
  plainData: PlainData[],
  t: Function
): AgnosticTableData => {
  const agnosticTableData = plainData.map((item) => {
    return {
      cells: columnDefinitions.map((columnDefinition) => {
        switch (columnDefinition.type) {
          case "link":
            return mapLink(
              item[columnDefinition.key] as string,
              item[columnDefinition.urlKey] as string
            );
          case "status":
            return mapStatus(columnDefinition, item[columnDefinition.key] as string, t);
          case "date":
            return mapDate(item[columnDefinition.key] as Date | null);
          case "text":
          default:
            return mapText(item[columnDefinition.key] as string);
        }
      }),
      plainData: item,
    };
  }) as AgnosticTableData;
  return agnosticTableData;
};
