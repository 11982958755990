import { TableManagerColumn } from "@telia/b2x-table/dist/types/components/table-column-manager/table-column-manager";
import * as ExcelJS from "exceljs";
import { AgnosticTableData } from "../../services/framework/level-2/agnostic-table-service";
import {
  BadgeCellData,
  LinkCellData,
  TextCellData,
} from "@telia/b2x-table/dist/types/components/table-cell/cell-data";
import { TableCellValue } from "@telia/b2x-table/dist/types/components/table-cell/table-cell-component-map";
import { getB2xTableData } from "../../services/framework/level-3/b2x-table-service";

const getColumnHeaders = (columns: TableManagerColumn[]) => {
  return columns.map((column) => column.title);
};

const getTableData = (deliveries: AgnosticTableData) => {
  return getB2xTableData(deliveries).map((row) => {
    return row.map((column) => {
      if (isLinkOrBadgeCellData(column)) {
        return column.content;
      }

      if (isTextCellData(column)) {
        return column;
      }

      // Should never reach this point since all columns should match either if statements
      return "";
    });
  });
};

const isLinkOrBadgeCellData = (column: TableCellValue): column is LinkCellData | BadgeCellData => {
  if ((column as LinkCellData | BadgeCellData).content) {
    return true;
  }
  return false;
};

const isTextCellData = (column: TableCellValue): column is TextCellData => {
  if (typeof column == "string" || typeof column === "number") {
    return true;
  }
  return false;
};

export const exportDataAsCSV = async (
  columns: TableManagerColumn[],
  tableData: AgnosticTableData,
  filename: string
): Promise<void> => {
  const dataArr: Array<string> = [];

  const exportColumnHeaders = getColumnHeaders(columns);
  const exportTableData = getTableData(tableData);

  dataArr.push(exportColumnHeaders.map((col: string) => `"${col}"`).join(";"));
  exportTableData.forEach((row) => {
    dataArr.push(row.map((cellData) => `"${cellData}"`).join(";"));
  });

  const dataToExport = "\uFEFF" + dataArr.join("\n");
  await downloadData(new Blob([dataToExport], { type: "text/csv;charset=UTF-8" }), filename);
};

export const exportDataAsXLSX = async (
  columns: TableManagerColumn[],
  tableData: AgnosticTableData,
  filename: string
): Promise<void> => {
  const exportColumnHeaders = getColumnHeaders(columns);
  const exportTableData = getTableData(tableData);
  const cellSizeArr = sampleCellWidth(exportColumnHeaders, exportTableData);

  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet();
  worksheet.columns = exportColumnHeaders.map((col, index) => {
    return { header: col, width: cellSizeArr[index] };
  });
  worksheet.getRow(1).font = { bold: true, size: 12 };
  worksheet.autoFilter = {
    from: "A1",
    to: `${String.fromCharCode("A".charCodeAt(0) + exportColumnHeaders.length - 1)}1`,
  };
  exportTableData.forEach((row) => worksheet.addRow(row));

  const buffer = await workbook.xlsx.writeBuffer();
  await downloadData(
    new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    }),
    filename
  );
};

const downloadData = async (blob: Blob, filename: string): Promise<void> => {
  try {
    // Create a download link
    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = filename;
    // Trigger the download
    document.body.appendChild(downloadLink);

    downloadLink.click();
    // Clean up
    setTimeout(() => {
      URL.revokeObjectURL(downloadLink.href);
      document.body.removeChild(downloadLink);
    }, 100);
  } catch (error: unknown) {
    throw new Error(`Error occurred when downloading file: ${filename}`);
  }
};

//  raverse columns and data to find a suitable cell width
const sampleCellWidth = (
  columnTitles: string[],
  exportData: Array<(string | number)[]>
): number[] => {
  const DATA_MAX_WIDTH = 50;
  const cellSizeArr: number[] = [];
  columnTitles.forEach((colTitle) => cellSizeArr.push(colTitle.length));

  exportData.forEach((row) => {
    row.forEach((col, index) => {
      if (cellSizeArr[index] < `${col}`.length) {
        cellSizeArr[index] = Math.min(`${col}`.length, DATA_MAX_WIDTH);
      }
    });
  });

  const CELL_SPACING = 4; // add some spacing
  return cellSizeArr.map((cellSize) => cellSize + CELL_SPACING);
};
