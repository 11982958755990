import type { ColumnDefinition } from "../../framework/level-2/agnostic-table-service";

export const COLUMN_DEFINITIONS: ColumnDefinition[] = [
  {
    key: "deliveryId",
    type: "link",
    defaultVisibility: true,
    columnGroup: "general",
    urlKey: "detailsUrl",
  },
  {
    key: "statusCategory",
    type: "status",
    defaultVisibility: true,
    columnGroup: "general",
    notSelectedDisplayNameTranslationKey: "value.orderStatus.groupedCategory.all",
    fallbackValue: "CLOSED",
    statusValues: {
      OPEN: {
        displayFilterTranslationKey: "value.orderStatus.groupedCategory.open",
        displayTranslationKey: "value.orderStatus.singleItemCategory.open",
        sortValue: "1",
        filterSortValue: "1",
        badgeType: "information",
      },
      CLOSED: {
        displayFilterTranslationKey: "value.orderStatus.groupedCategory.closed",
        displayTranslationKey: "value.orderStatus.singleItemCategory.closed",
        sortValue: "2",
        filterSortValue: "2",
        badgeType: "success",
      },
    },
  },
  {
    key: "subscriptionId",
    type: "text",
    defaultVisibility: true,
    columnGroup: "general",
  },
  {
    key: "serviceType",
    type: "text",
    defaultVisibility: false,
    columnGroup: "general",
  },
  {
    key: "deliveryType",
    type: "text",
    defaultVisibility: true,
    columnGroup: "general",
  },
  {
    key: "vpnId",
    type: "text",
    defaultVisibility: false,
    columnGroup: "general",
  },
  {
    key: "vpnAlias",
    type: "text",
    defaultVisibility: false,
    columnGroup: "general",
  },
  {
    key: "organizationName",
    type: "text",
    defaultVisibility: false,
    columnGroup: "customer",
  },
  {
    key: "installationName",
    type: "text",
    defaultVisibility: false,
    columnGroup: "customer",
  },
  {
    key: "installationAddress",
    type: "text",
    defaultVisibility: false,
    columnGroup: "customer",
  },
  {
    key: "installationZipCode",
    type: "text",
    defaultVisibility: false,
    columnGroup: "customer",
  },
  {
    key: "installationCity",
    type: "text",
    defaultVisibility: true,
    columnGroup: "customer",
  },
  {
    key: "installationCountry",
    type: "text",
    defaultVisibility: false,
    columnGroup: "customer",
  },
  {
    key: "contactPerson",
    type: "text",
    defaultVisibility: false,
    columnGroup: "customer",
  },
  {
    key: "contactPhone",
    type: "text",
    defaultVisibility: false,
    columnGroup: "customer",
  },
  {
    key: "registeredDate",
    type: "date",
    defaultVisibility: true,
    columnGroup: "dates",
  },
  {
    key: "customerDesiredDate",
    type: "date",
    defaultVisibility: false,
    columnGroup: "dates",
  },
  {
    // the originally promised date
    key: "promisedDate",
    type: "date",
    defaultVisibility: false,
    columnGroup: "dates",
  },
  {
    // latestPromisedDate is basically "newPromisedDate" if it exists, otherwise "promisedDate"
    key: "latestPromisedDate",
    type: "date",
    defaultVisibility: true,
    columnGroup: "dates",
  },
  {
    key: "readyDate",
    type: "date",
    defaultVisibility: true,
    columnGroup: "dates",
  },
];
