import { AgnosticTableData, AgnosticRowData } from "./agnostic-table-service";

export type SortOrder = "ASC" | "DESC";

export type SortDefinition = {
  columnIndex: number;
  order: SortOrder;
};

const sortTable = (
  filteredDeliveries: AgnosticTableData,
  sortDefinitions: SortDefinition[] // the first item will be the primary sort order. Identical items will be sorted according to the second item, etc.
): AgnosticTableData => {
  // going to alter the array by sorting it, but we never alter the inner items.
  let deliveriesShallowCopy = [...filteredDeliveries];

  // do a single pass of the table and compare the sort definitions recursively.
  // slower method is to just sort the table three times
  deliveriesShallowCopy.sort((a: AgnosticRowData, b: AgnosticRowData) =>
    recursiveCompare(a, b, sortDefinitions)
  );

  return deliveriesShallowCopy;
};

const recursiveCompare = (
  a: AgnosticRowData,
  b: AgnosticRowData,
  sortDefinitions: SortDefinition[]
): number => {
  if (sortDefinitions.length === 0) {
    // no more sort definitions - leave them in original order
    return 0;
  }

  if (sortDefinitions[0].columnIndex >= 0) {
    const reverseFactor = sortDefinitions[0].order === "ASC" ? 1 : -1;
    if (
      a.cells[sortDefinitions[0].columnIndex].sortData <
      b.cells[sortDefinitions[0].columnIndex].sortData
    ) {
      return -1 * reverseFactor;
    } else if (
      a.cells[sortDefinitions[0].columnIndex].sortData >
      b.cells[sortDefinitions[0].columnIndex].sortData
    ) {
      return 1 * reverseFactor;
    }
  }

  // the two items were the same, or sort column was not specified, try less significant sort order
  return recursiveCompare(a, b, sortDefinitions.slice(1));
};

const getPage = (
  filteredAndSortedDeliveries: AgnosticTableData,
  pageSize: number,
  oneBasedPageNumber: number
): AgnosticTableData =>
  filteredAndSortedDeliveries.slice(
    (oneBasedPageNumber - 1) * pageSize,
    oneBasedPageNumber * pageSize
  );

const isStatusFilterMatch = (selectedFilter: string, rowValue: string) =>
  selectedFilter === "" || selectedFilter === rowValue;

const filterTable = (
  allUntouchedDeliveries: AgnosticTableData,
  filters: Record<number, { text: string }>
): AgnosticTableData => {
  return allUntouchedDeliveries.filter((row) => {
    for (const filteredColumn in filters) {
      if (row.cells[filteredColumn].type === "status") {
        if (
          !isStatusFilterMatch(filters[filteredColumn].text, row.cells[filteredColumn].filterData)
        ) {
          return false;
        }
      } else if (row.cells[filteredColumn].type === "text" || row.cells[filteredColumn].type === "link") {
        if (
          !row.cells[filteredColumn]
            .filterData!.toString()
            .toLowerCase()
            .includes(filters[filteredColumn].text.toLowerCase())
        ) {
          return false;
        }
      } else if (row.cells[filteredColumn].type === "date") {
        // temporary solution, should be replaced with proper date range filtering
        if (
          !row.cells[filteredColumn]
            .filterData!.toString()
            .toLowerCase()
            .includes(filters[filteredColumn].text.toLowerCase())
        ) {
          return false;
        }
      }
    }
    return true;
  });
};

export const filterAndSortTable = (
  allUntouchedDeliveries: AgnosticTableData,
  filters: Record<number, { text: string }>,
  sortDefinitions: SortDefinition[]
): AgnosticTableData => sortTable(filterTable(allUntouchedDeliveries, filters), sortDefinitions);

export const getPageOfTable = (
  sortedAndFilteredDeliveries: AgnosticTableData,
  pageSize: number,
  oneBasedPageNumber: number
): AgnosticTableData => getPage(sortedAndFilteredDeliveries, pageSize, oneBasedPageNumber);
