import { logError } from "@telia/b2x-logging";

import { corpInstalledbaseDatacom, corpInstalledbaseVpn } from "@telia/b2b-rest-client";
import { getOrganizations } from "@telia/b2b-logged-in-service";

import type { PlainDatacomDeliveryData } from "./mapper";
import { mapDeliveries } from "./mapper";

export const getDeliveriesFromBackend = async (
  scopeId: string
): Promise<PlainDatacomDeliveryData[]> => {
  const responses = await Promise.all([
    corpInstalledbaseDatacom.DeliveriesControllerImplService.getDatacomDeliveriesUsingGet(scopeId),
    getOrganizations().catch(() => {
      logError(
        "b2b-datacom-deliveries",
        "Could not retrieve organizations, will show org numbers instead"
      );
      return [];
    }),
    corpInstalledbaseVpn.VpnControllerService.getVpnsUsingGet(scopeId).catch(() => {
      logError("b2b-datacom-deliveries", "Could not retrieve VPN aliasas, will show empty column");
      return [];
    }),
  ]);

  return mapDeliveries(responses[0], responses[1], responses[2], scopeId);
};
