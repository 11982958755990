<template>
  <div class="deliveries-table" :class="[{ 'wide-table': isWideTable }]">
    <telia-grid>
      <telia-row>
        <telia-col width="12">
          <b2x-controlled-table
            :column-filter-label="t('mybusiness.columnManager')"
            :reset-column-label="t('mybusiness.resetColumns')"
            :columns="JSON.stringify(columns)"
            :data="JSON.stringify(b2xTablePageOfData)"
            :is-wide-table="isWideTable"
            :message="tableMessage"
            :clear-filters-button-disabled="false"
            :table-rows-count="filteredAndSortedAgnosticTableData.length"
            :table-rows-limit="b2xTablePageOfData.length"
            :export-btn-label="t('mybusiness.export')"
            :export-types="JSON.stringify(types)"
            :export-line-label="t('mybusiness.exportAs')"
            show-filter-row
            show-clear-filters-button
            show-expand-button
            @filterColumnInput="onFilterColumnInput"
            @columnClick="onColumnClick"
            @clearAllFilters="onClearAllFilters"
            @controlledTableExpandClicked="toggleIsWideTable"
            @exportData="exportData"
          ></b2x-controlled-table>
          <b2x-paginator
            :list-length="filteredAndSortedAgnosticTableData.length"
            :page-sizes="JSON.stringify(pageSizes)"
            :default-page-size="pageSize"
            :page-number="oneBasedPageNumber"
            @paginationChange="onPaginationChange"
          ></b2x-paginator>
        </telia-col>
      </telia-row>
    </telia-grid>
  </div>
</template>

<script setup lang="ts">
import { computed, ComputedRef, Ref, ref } from "vue";

import type {
  AgnosticTableData,
  ColumnDefinition,
} from "../services/framework/level-2/agnostic-table-service";
import type {
  SortDefinition,
  SortOrder,
} from "../services/framework/level-2/transformation-service";
import {
  filterAndSortTable,
  getPageOfTable,
} from "../services/framework/level-2/transformation-service";

import { COLUMN_DEFINITIONS } from "../services/datacom-deliveries/level-2/column-definitions";

import type { TableEntry } from "@telia/b2x-table/dist/types/components/table/table";
import type { TableManagerColumn } from "@telia/b2x-table/dist/types/components/table-column-manager/table-column-manager";

import { exportDataAsXLSX, exportDataAsCSV } from "./export/helper";

import { addNewToast } from "@telia/b2b-message-service";

import {
  getB2xTableColumns,
  getB2xTableData,
} from "../services/framework/level-3/b2x-table-service";
import { logError } from "@telia/b2x-logging";

interface Props {
  deliveries: AgnosticTableData;
  t: (key: string, data?: Record<string, string>) => string;
}

const props = defineProps<Props>();
const t = props.t;

const types = [".xls", ".csv"] as const;
const toastIdNr = ref(0);

const getColumnIndexFromTitle = (title: string | null): number =>
  columns.value.findIndex((column: TableManagerColumn) => column.title === title);

const getColumnIndexFromKey = (key: string): number =>
  //COLUMN_DEFINITIONS and columns map indexes 1:1
  COLUMN_DEFINITIONS.findIndex(
    (columnDefinition: ColumnDefinition) => columnDefinition.key === key
  );

const columns: ComputedRef<TableManagerColumn[]> = computed(() =>
  getB2xTableColumns(COLUMN_DEFINITIONS, sortColumnIndex.value, sortOrder.value, t)
);

const deliveries: Ref<AgnosticTableData> = computed(() => props.deliveries);

// items that are identical according to the user's selected sort order
// should internally be sorted in this order
const defaultSortOrders: SortDefinition[] = [
  {
    columnIndex: getColumnIndexFromKey("statusCategory"),
    order: "ASC",
  },
  {
    columnIndex: getColumnIndexFromKey("latestPromisedDate"),
    order: "DESC",
  },
];

const filteredAndSortedAgnosticTableData: ComputedRef<AgnosticTableData> = computed(() =>
  filterAndSortTable(
    deliveries.value,
    filters.value,
    (sortColumnIndex.value !== null
      ? [
          {
            columnIndex: sortColumnIndex.value as number,
            order: sortOrder.value,
          },
        ]
      : []
    ).concat(defaultSortOrders)
  )
);

// by default, sort on status column
const defaultSortColumnIndex = 1;
const defaultSortColumnKey = "statusCategory";
let sortColumnIndex: Ref<number | null> = ref(getColumnIndexFromKey(defaultSortColumnKey));

const defaultSortOrder = "ASC";
let sortOrder: Ref<SortOrder> = ref(defaultSortOrder);

let filters: Ref<Record<number, { text: string }>> = ref({});

let b2xTablePageOfData: Ref<TableEntry[]> = computed(() =>
  getB2xTableData(
    getPageOfTable(
      filteredAndSortedAgnosticTableData.value,
      pageSize.value,
      oneBasedPageNumber.value
    )
  )
);

let pageSizes: Ref<number[]> = ref([10, 25, 50, 100]);
let pageSize: Ref<number> = ref(10);
let oneBasedPageNumber: Ref<number> = ref(1);

const onFilterColumnInput = (event) => {
  oneBasedPageNumber.value = 1;

  // setting the proeprties directly on the object works in browser, but not in unit tests
  // (computed properties depending on it are not re-computed)
  const newObject = JSON.parse(JSON.stringify(filters.value));
  newObject[getColumnIndexFromTitle(event.detail.column)] = { text: event.detail.value };
  filters.value = newObject;
};
const onColumnClick = (event) => {
  oneBasedPageNumber.value = 1;
  const newColumnIndex = getColumnIndexFromTitle(event.detail);

  if (sortColumnIndex.value !== newColumnIndex || sortOrder.value === "DESC") {
    sortOrder.value = "ASC";
  } else {
    sortOrder.value = "DESC";
  }
  sortColumnIndex.value = newColumnIndex;
};

const tableMessage: ComputedRef<string | null> = computed(() => {
  if (deliveries.value.length <= 0) {
    // there exist no entries, even though o filters are applied
    return t("noEntriesWithoutFilter");
  }

  if (filteredAndSortedAgnosticTableData.value.length <= 0) {
    // there exist 1 or more entry, but due to filters, none of them are shown
    return t("noEntriesWithFilter");
  }

  // there exist 1 or more entry, even if filters are enabled
  return null; // no message
});

const onClearAllFilters = () => {
  sortColumnIndex.value = defaultSortColumnIndex;
  sortOrder.value = defaultSortOrder;
  filters.value = {};
  oneBasedPageNumber.value = 1;
};

let isWideTable: Ref<boolean> = ref(false);

const toggleIsWideTable = () => {
  isWideTable.value = !isWideTable.value;
};

const onPaginationChange = (event: CustomEvent) => {
  oneBasedPageNumber.value = event.detail.page;
  pageSize.value = event.detail.pageSize;
};

const exportData = async (event: CustomEvent) => {
  try {
    const type = event.detail;

    const name = t("pageTitle");

    if (type === types[0]) {
      await exportDataAsXLSX(columns.value, props.deliveries, name);
    }

    if (type === types[1]) {
      await exportDataAsCSV(columns.value, props.deliveries, name);
    }
  } catch {
    addNewToast(
      `export-table-${toastIdNr.value++}`,
      "error",
      t("exportError.title"),
      t("exportError.body")
    );
    logError("b2b-datacom-deliveries", "Error occurred when exporting table");
  }
};
</script>

<style lang="scss" scoped>
.deliveries-table telia-grid {
  transition: max-width 0.3s ease-in;
}
.deliveries-table.wide-table telia-grid {
  max-width: 100%;
}
</style>
