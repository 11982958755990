<template>
  <b2b-layout :heading="t('pageTitle')" :page-title="t('pageTitle')" show-organisation-selector="false">
    <telia-grid slot="header">
      <telia-row>
        <telia-col width="12">
          <!-- preamble-200 according to UX guidelines -->
          <telia-p variant="preamble-200">{{ t("preamble") }}</telia-p>
        </telia-col>
      </telia-row>
    </telia-grid>

    <telia-grid v-if="state === 'UNEXPECTED_ERROR' || state === 'LOADING'">
      <telia-row>
        <telia-col width="12">
          <b2x-result-message v-if="state === 'UNEXPECTED_ERROR'" graphic="technical-error" heading-tag="h2"
            :heading="t('unexpectedError.heading')" :complete-description="t('unexpectedError.completeDescription')"
            action-type="button" :action-label="t('unexpectedError.buttonLabel')" action-left-icon="undo"
            @buttonClick="init()"></b2x-result-message>
          <b2x-table-skeleton v-else-if="state === 'LOADING'" :rows="pageSize"
            :columns="COLUMN_DEFINITIONS.filter((column) => column.defaultVisibility).length"
            show-header="true"></b2x-table-skeleton>
        </telia-col>
      </telia-row>
    </telia-grid>
    <deliveries-table v-else :deliveries="deliveries" :t="t" />

    <details-drawer :t="t" :selected-item="selectedItem" @drawerClose="hideDetailsModal" />
  </b2b-layout>
</template>

<script setup lang="ts">
import "@telia/b2b-layout";
import "@telia/b2x-paginator";
import "@telia/b2x-result-message";
import "@telia/b2x-table";

import { computed, ComputedRef, onUnmounted, Ref, ref } from "vue";
import { navigateToUrl } from "single-spa";
import { match } from "path-to-regexp";

import { translateMixin, translateSetup } from "./locale";

import { logError } from "@telia/b2x-logging";

import { getDeliveriesFromBackend } from "./services/datacom-deliveries/level-1/plain-data-service";
import { PlainDatacomDeliveryData } from "./services/datacom-deliveries/level-1/mapper"

import type { AgnosticTableData, AgnosticRowData } from "./services/framework/level-2/agnostic-table-service";
import { getAgnosticTableData } from "./services/framework/level-2/agnostic-table-service";
import { COLUMN_DEFINITIONS } from "./services/datacom-deliveries/level-2/column-definitions";
import type { DetailsListData } from "./services/datacom-deliveries/level-3/details-page-service";
import { getDetails } from "./services/datacom-deliveries/level-3/details-page-service";

import { getScopeIdOrThrow } from "@telia/b2b-customer-scope";
import DetailsDrawer from "./components/details-drawer.vue";
import DeliveriesTable from "./components/deliveries-table.vue";

translateSetup(["mybusiness"]);

const t = translateMixin.methods.t;

const state: Ref<"LOADING" | "UNEXPECTED_ERROR" | "SUCCESS"> = ref("LOADING");

let scopeId: Ref<string | null> = ref(null);
let deliveries: Ref<AgnosticTableData> = ref([]);

const init = (): Promise<void> => {
  state.value = "LOADING";

  return (
    async () => {
      try {
        scopeId.value = await getScopeIdOrThrow();

        deliveries.value = getAgnosticTableData(
          COLUMN_DEFINITIONS,
          await getDeliveriesFromBackend(
            (
              scopeId.value ?? ""
            )
          ),
          t
        );
        state.value = "SUCCESS";
      } catch {
        logError("b2b-datacom-deliveries", "Failed to load datacom deliveries");
        state.value = "UNEXPECTED_ERROR";
      }
    })();
};
init();

let pageSize: Ref<number> = ref(10);

// the item ID in URL, synked by popstate events
let selectedItemId: Ref<number | null> = ref(null);

// index in the main table
let selectedItem: ComputedRef<
  | {
    deliveryId: string;
    generalDetails: DetailsListData;
    deliveryDetails: DetailsListData;
    subscriptionDetails: DetailsListData;
  }
  | "NONE"
  | "ERROR"
> = computed(() => {
  if (!selectedItemId.value || state.value !== "SUCCESS") {
    return "NONE";
  }

  const foundItemIndex = deliveries.value.findIndex(
    (row: AgnosticRowData) => (row.plainData['deliveryId'] as string) == selectedItemId.value?.toString()
  );

  if (foundItemIndex < 0) {
    return "ERROR";
  }

  return getDetails(deliveries.value[foundItemIndex].plainData as PlainDatacomDeliveryData, t);
});

// on initial page load, OR if it changes - get selected item from URL
const onPopState = () => {
  const pathMatch = match(
    "/foretag/mybusiness/:scopeId/hantera/produkter-tjanster/datanet-vpn/leveranser/:selectedItemIdFromUrl?"
  )(window.location.pathname); // returns false, or an object with a params object

  if (pathMatch && pathMatch.params["selectedItemIdFromUrl"]) {
    selectedItemId.value = pathMatch.params["selectedItemIdFromUrl"];
  } else {
    selectedItemId.value = null;
  }
};
// act on clicks which add/change the ID in the URL
window.addEventListener("popstate", onPopState);
// unregister listener if we navigate to other SSPA page
onUnmounted(() => window.removeEventListener("popstate", onPopState));
// get item if URL initiatally contained an ID
onPopState();

const hideDetailsModal = () => {
  navigateToUrl(
    `/foretag/mybusiness/${scopeId.value}/hantera/produkter-tjanster/datanet-vpn/leveranser`
  );
};
</script>

<style lang="scss" scoped></style>
